<template>
    <div>
        <section class="hero is-small" style="padding-top: 35px">
            <div class="hero-body">
                <div class="container">
                    <Notification />

                    <div class="columns is-vcentered">
                        <div class="column">
                            <h1 class="title is-size-2">Pfadi Hochwacht Baden</h1>
                            <div class="is-size-6">
                                Sagen dir Schnitzeljagde, Zeltstädte, Lagerfeuer und Theater zu?
                                Entdecke unter Freunden dein nächstes Abenteuer.
                            </div>
                            <o-button label="Schau mal vorbei!" variant="primary" style="margin-top: 20px"
                                @click="modalOpen = true"></o-button>
                            <o-modal v-model:active="modalOpen" scroll="keep">
                                <div class="card">
                                    <div class="card-content">
                                        <div class="content">
                                            <div v-if="popup_content.data" class="modal-card card-content">
                                                <UtilMarkdown :source="popup_content.data.attributes.text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </o-modal>
                            <div class="organisations-small">
                                <a v-for="(org, index) in organisations" v-bind:key="index" :href="org.url"
                                    target="blank" rel="noopener">
                                    <img :src="'/img/links/' + org.image" :alt="org.name + ' Logo'" />
                                </a>
                            </div>
                        </div>
                        <div class="column side-image is-offset-1 is-hidden-mobile">
                            <img src="/img/night.svg" aria-hidden="true" class="hero-image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-small">
            <div class="hero-body">
                <div class="container gallery-container">
                    <div class="title-wrapper has-text-centered">
                        <h2 class="title is-size-2">Galerie</h2>
                        <div class="subtitle">
                            Sehe dir weitere Pfadierlebnisse in der
                            <nuxt-link to="/galleries"> Galerie</nuxt-link> an.
                        </div>
                    </div>
                    <div class="gallery-container__galleries">
                        <div
                            v-for="gallery in galleries" v-bind:key="gallery.id">
                            <nuxt-link :to="'/galleries/' + gallery.id">
                                <gallery-card :gallery="gallery" />
                            </nuxt-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="hero is-small">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-8 is-vcentered is-centered">
                        <div class="column is-4" v-for="flyer in documents" v-bind:key="flyer.id">
                            <a :href="config.public.apiUrl + flyer.file.url">
                                <img class="flyer" :src="config.public.apiUrl + flyer.thumbnail.url"
                                    :alt="flyer.description" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { type Document } from '~/ts/interfaces/Document';
import type { Gallery } from '~/ts/interfaces/Gallery';
import type { IntroPopup } from '~/ts/interfaces/IntroPopup';
import type { Pagination } from '~/ts/interfaces/strapi/Pagination';
import type { StrapiData, StrapiDataItem } from '~/ts/interfaces/strapi/StrapiData';

const config = useRuntimeConfig()
useSeoMeta({
    title: `${config.public.meta.fullTitle}`,
    ogTitle: `${config.public.meta.fullTitle}`,
    description: config.public.meta.description,
    ogDescription: config.public.meta.description,
    ogImage: config.public.meta.image
});


interface Organisation {
    name: string;
    url: string;
    image: string;
}
const documents = ref<Document[]>([]);
const galleries = ref<StrapiDataItem<Gallery>[]>([]);
const popup_content = ref<StrapiData<IntroPopup>>({} as StrapiData<IntroPopup>);
const modalOpen = ref<boolean>(false);
// const popup_content: MdContent = {} as MdContent;

const organisations: Organisation[] = [
    {
        name: "Pfadi Schweiz",
        url: "https://www.scout.ch/de/verband/pfadibewegung-schweiz?set_language=de",
        image: "pbs_logo.svg",
    },
    {
        name: "Pfadi Aargau",
        url: "https://www.pfadiaargau.ch/",
        image: "pfadi_aargau_logo.png",
    },
    {
        name: "Facebook",
        url: "https://www.facebook.com/Pfadi-Hochwacht-Baden-117341116342475/",
        image: "bookface.png",
    },
    {
        name: "Instagram",
        url: "https://www.instagram.com/pfadihochwachtbaden/",
        image: "instagram.png",
    },
    {
        name: "Youtube",
        url: "https://www.youtube.com/channel/UCj3aGT7Ahv7LicTEcNUdqGg",
        image: "youtube.png",
    },
];

onMounted(async () => {
    const popupContentResponse = await fetch(`${config.public.apiUrl}/api/intro-popup`);
    popup_content.value = await popupContentResponse.json();
    // Fetsch documents where is_flyer == true
    const documentsResponse = await fetch(`${config.public.apiUrl}/api/documents`);
    documents.value = await documentsResponse.json();
    documents.value = documents.value.filter((document: Document) => document.is_flyer);
    // Fetch the latest 3 galleries
    const galleriesResponse = await fetch(`${config.public.apiUrl}/api/galleries?populate[images]=*&populate[activity]=*&pagination[limit]=3&sort[1]=activity.start_date:DESC`);
    const paginatedGalleries = await galleriesResponse.json() as Pagination<Gallery>;
    galleries.value = paginatedGalleries.data ?? [];
    // only store the first 3 galleries
    galleries.value = galleries.value.slice(0, 3);
    // Fetch the popup content
})
</script>

<style lang="scss">
.organisations-small {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    padding: 15px 0;

    img {
        height: 38px;
        margin: 0 5px;
    }
}

.gallery-container {
    padding-top: 40px;

    &__galleries {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 0.75rem;
        margin-top: 2.5rem;

        @media screen and (max-width: 768px) {
            > div {
                width: 100%;
            }
        }
    }
}

.side-image {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flyer {
    border: 1px solid #ccc;
    border-radius: 15px;
}
</style>